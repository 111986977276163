import { Field } from 'Util/Query';
/** @namespace Pwa/Query/AmastyBundleProducts/Query/AmastyBundleProductsQuery */
export class AmastyBundleProductsQuery {
    getAmastyBundleProducts(uid) {
        const query = new Field('amMostviewedBundlePacks')
            .addArgument('uid', 'String!', uid)
            .addFieldList(this._getAmastyBundleProductsFields());

        return query;
    }

    _getAmastyBundleProductsFields() {
        return [this._getAmastyBundleProductsItemsFields(), this._getAmastyBundleProductsMainProductFields()];
    }

    _getAmastyBundleProductsMainProductFields() {
        return new Field('main_product').addFieldList([
            'deliverer_available',
            'discount_display',
            'id',
            'name',
            'lowest_price',
            'product_unit',
            'uid',
            'sku',
            'qty',
            'type_id',
            'stock_status',
            'salable_qty',
            'total_saves',
            'url',
            'product_unit',
            'vp_items',
            this._getStockAvailability(),
            this._getAlternativeHoverImageField(),
            this._getProductImageField(),
            this._getLabelsField(),
            this._getPriceRangeField(),
            this._getProductSmallField(),
        ]);
    }

    _getAmastyBundleProductsItemsFields() {
        return new Field('items').addFieldList([
            'block_title',
            'discount_amount',
            'discount_type',
            'apply_condition',
            'pack_id',
            this._getAmastyBundleProductsItemsFieldsData(),
        ]);
    }

    _getAmastyBundleProductsItemsFieldsData() {
        return new Field('items').addFieldList([
            'qty',
            'discount_amount',
            this._getAmastyBundleProductsProductFieldsData(),
        ]);
    }

    _getAmastyBundleProductsProductFieldsData() {
        return new Field('product').addFieldList([
            'deliverer_available',
            'discount_display',
            'id',
            'name',
            'lowest_price',
            'product_unit',
            'uid',
            'sku',
            'type_id',
            'stock_status',
            'salable_qty',
            'total_saves',
            'url',
            'product_unit',
            'vp_items',
            this._getStockAvailability(),
            this._getAlternativeHoverImageField(),
            this._getProductImageField(),
            this._getLabelsField(),
            this._getPriceRangeField(),
            this._getProductSmallField(),
        ]);
    }

    _getStockAvailability() {
        return new Field('stock_availability').addFieldList(this._getStockAvailabilityFields());
    }

    _getStockAvailabilityFields() {
        return ['label', 'qty'];
    }

    _getAlternativeHoverImageField() {
        return new Field('alternative_hover_image').addFieldList(this._getAlternativeHoverImageFields());
    }

    _getAlternativeHoverImageFields() {
        return ['path', 'url'];
    }

    _getLabelsField() {
        return new Field('labels').addFieldList(this._getLabelsFields());
    }

    _getLabelsFields() {
        return ['code', 'type', 'image', 'url', 'class', 'text', 'text_color', 'text_background_color'];
    }

    _getPriceRangeField() {
        return new Field('price_range').addFieldList(this._getPriceRangeFields());
    }

    _getPriceRangeFields() {
        return [this._getMinimalPriceField(), this._getMaximalPriceField()];
    }

    _getMinimalPriceFields() {
        return [this._getDiscountField(), this._getFinalPriceField(), this._getRegularPriceField()];
    }

    _getMinimalPriceField() {
        return new Field('minimum_price').addFieldList(this._getMinimalPriceFields());
    }

    _getMaximalPriceField() {
        return new Field('maximum_price').addFieldList(this._getMinimalPriceFields());
    }

    _getDiscountField() {
        return new Field('discount').addField('amount_off').addField('percent_off');
    }

    _getFinalPriceField() {
        return new Field('final_price').addField('currency').addField('value');
    }

    _getRegularPriceField() {
        return new Field('regular_price').addField('currency').addField('value');
    }

    _getProductImageField() {
        return new Field('image').addFieldList(this._getProductThumbnailFields());
    }

    _getProductThumbnailFields() {
        return ['path', 'url'];
    }

    _getProductSmallField() {
        return new Field('small_image').addFieldList(this._getProductSmallFields());
    }

    _getProductSmallFields() {
        return this._getProductThumbnailFields();
    }

    addBundleProducts(cartId, items) {
        const mutation = new Field('addProductBundleToCart')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('cartItems', '[CartBundleItemInput!]!', items)
            .addField(this._getBundleItemsField());

        return mutation;
    }

    _getBundleItemsField() {
        return new Field('user_errors').addFieldList(['code', 'message']);
    }
}

export default new AmastyBundleProductsQuery();
