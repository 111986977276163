/* eslint-disable fp/no-let */
import React, { Component } from 'react';

import AmastyBundleProduct from 'Component/AmastyBundleProduct';
import ContentWrapper from 'Component/ContentWrapper';
import ProductDiscountLabel from 'Component/ProductDiscountLabel';
import { formatPrice } from 'Util/Price';

import {
    BUNDLE_DISCOUNT_FOR_ALL_CHECKED,
    BUNDLE_DISCOUNT_TYPE_FIXED,
    BUNDLE_DISCOUNT_TYPE_PERCENTAGE,
} from './AmastyBundleProducts.config';

import './AmastyBundleProducts.style.scss';

/** @namespace Pwa/Component/AmastyBundleProducts/Component/AmastyBundleProducts */
export class AmastyBundleProducts extends Component {
    renderSectionTitle() {
        const { block_title, isHiddenElement, isFirstHiddenElement, device: { isMobile, isTablet } = {} } = this.props;

        if (isHiddenElement && !isFirstHiddenElement && !isMobile && !isTablet) {
            return null;
        }

        return (
            <h2 block="AmastyBundleProducts" elem="SectionTitle">
                {block_title}
            </h2>
        );
    }

    renderPageItemsBlock() {
        const { apply_condition, items, discount_type, discount_amount, updateCheckedProducts, checkedProducts } =
            this.props;

        const checkedBundleItems = items.filter((item) =>
            checkedProducts?.find((checkedItem) => checkedItem?.itemId === item?.product?.id)
        );
        const allItemsAreChecked = checkedBundleItems?.length === items?.length;
        const allItemsShouldBeCheckedForDiscount = apply_condition === BUNDLE_DISCOUNT_FOR_ALL_CHECKED;

        return items.map((item, i) => (
            <AmastyBundleProduct
                product={item?.product}
                key={`amastyBundleProduct${i}`}
                hiddenHoverSection
                hiddenActions
                isBundle
                discountType={discount_type}
                discountValue={item?.discount_amount || discount_amount}
                onCheckboxClick={updateCheckedProducts}
                bundleDiscountDisabled={allItemsShouldBeCheckedForDiscount && !allItemsAreChecked}
                isLink
            />
        ));
    }

    renderMainBundleProduct() {
        const { apply_condition, items, discount_type, mainProduct, discount_amount, checkedProducts } = this.props;

        const checkedBundleItems = items.filter((item) =>
            checkedProducts?.find((checkedItem) => checkedItem?.itemId === item?.product?.id)
        );
        const allItemsAreChecked = checkedBundleItems?.length === items?.length;
        const allItemsShouldBeCheckedForDiscount = apply_condition === BUNDLE_DISCOUNT_FOR_ALL_CHECKED;

        return (
            <AmastyBundleProduct
                product={mainProduct}
                hiddenHoverSection
                hiddenActions
                discountType={discount_type}
                discountValue={discount_amount}
                bundleDiscountDisabled={allItemsShouldBeCheckedForDiscount && !allItemsAreChecked}
            />
        );
    }

    renderItems() {
        const { items, isLoading } = this.props;

        if (isLoading) {
            return this.renderPlaceholderItems();
        }

        if (!items?.length && !isLoading) {
            return null;
        }

        return (
            <ul block="AmastyBundleProducts" elem="List">
                {this.renderMainBundleProduct()}
                {this.renderPageItemsBlock()}
            </ul>
        );
    }

    calculateRegularPrice() {
        let regularPrice = 0;
        const { items, mainProduct, checkedProducts } = this.props;

        const bundledItems = items?.map((item) => item?.product);
        const checkedBundleItems = bundledItems.filter((item) =>
            checkedProducts?.find((checkedItem) => checkedItem?.itemId === item?.id)
        );
        const allBundleItem = [mainProduct, ...checkedBundleItems];

        allBundleItem.forEach((item) => {
            const { price_range: { maximum_price: { regular_price = {} } = {} } = {} } = item;

            if (!regular_price?.value) {
                return null;
            }
            regularPrice += regular_price?.value;
        });

        return regularPrice;
    }

    calculateFinalPrice() {
        let finalPrice = 0;
        const {
            apply_condition,
            items,
            discount_amount: discountValue,
            mainProduct,
            discount_type: discountType,
            checkedProducts,
        } = this.props;

        const checkedBundleItems = items.filter((item) =>
            checkedProducts?.find((checkedItem) => checkedItem?.itemId === item?.product?.id)
        );
        const allItemsAreChecked = checkedBundleItems?.length === items?.length;
        const allItemsShouldBeCheckedForDiscount = apply_condition === BUNDLE_DISCOUNT_FOR_ALL_CHECKED;

        checkedBundleItems?.forEach((item) => {
            const {
                product: {
                    price_range: {
                        maximum_price: { regular_price = {}, final_price = {}, discount: { amount_off } } = {},
                    } = {},
                } = {},
                discount_amount,
            } = item;
            const baseDiscountAvailable = amount_off > 0;
            const discount = discount_amount || discountValue;

            if (allItemsShouldBeCheckedForDiscount && !allItemsAreChecked) {
                finalPrice += baseDiscountAvailable ? final_price?.value : regular_price?.value;
                return;
            }

            if (discountType === BUNDLE_DISCOUNT_TYPE_FIXED) {
                finalPrice += baseDiscountAvailable
                    ? +(final_price?.value - discount).toFixed(2)
                    : +(regular_price?.value - discount).toFixed(2);
            }

            if (discountType === BUNDLE_DISCOUNT_TYPE_PERCENTAGE) {
                finalPrice += baseDiscountAvailable
                    ? +(final_price?.value - final_price?.value * (discount / 100)).toFixed(2)
                    : +(regular_price?.value - regular_price?.value * (discount / 100)).toFixed(2);
            }
        });

        const {
            price_range: {
                maximum_price: { regular_price = {}, final_price = {}, discount: { amount_off } } = {},
            } = {},
        } = mainProduct;
        const baseDiscountAvailable = amount_off > 0;
        const discount = discountValue;

        if (allItemsShouldBeCheckedForDiscount && !allItemsAreChecked) {
            finalPrice += baseDiscountAvailable ? final_price?.value : regular_price?.value;
        } else if (discountType === BUNDLE_DISCOUNT_TYPE_FIXED) {
            finalPrice += baseDiscountAvailable
                ? +(final_price?.value - discount).toFixed(2)
                : +(regular_price?.value - discount).toFixed(2);
        } else if (discountType === BUNDLE_DISCOUNT_TYPE_PERCENTAGE) {
            finalPrice += baseDiscountAvailable
                ? +(final_price?.value - final_price?.value * (discount / 100)).toFixed(2)
                : +(regular_price?.value - regular_price?.value * (discount / 100)).toFixed(2);
        }

        return finalPrice;
    }

    calculateDiscountLabelValue() {
        const discountLabelValue = +(this.calculateRegularPrice() - this.calculateFinalPrice()).toFixed(2);

        return discountLabelValue;
    }

    renderRegularPrice() {
        const regularPrice = this.calculateRegularPrice();
        const { currency } = this.props;

        return (
            <div block="AmastyBundleProducts" elem="PriceWrapper" mods={{ type: 'regularPrice' }}>
                <div>{__('Regular price')}:</div>
                <span>{formatPrice(regularPrice, currency)}</span>
            </div>
        );
    }

    renderFinalPrice() {
        const finalPrice = this.calculateFinalPrice();
        const { currency } = this.props;

        return (
            <div block="AmastyBundleProducts" elem="PriceWrapper" mods={{ type: 'promoPrice' }}>
                <div>{__('Promo price')}:</div>
                <span>{formatPrice(finalPrice, currency)}</span>
            </div>
        );
    }

    renderDiscount() {
        const discountLabelValue = this.calculateDiscountLabelValue();
        const { currency } = this.props;

        return (
            <div block="AmastyBundleProducts" elem="PriceWrapper" mods={{ type: 'discount' }}>
                <div block="AmastyBundleProducts" elem="DiscountTitle">
                    {__('You will save')}
                </div>
                <ProductDiscountLabel discountType="fixed" fixedDiscount={formatPrice(discountLabelValue, currency)} />
            </div>
        );
    }

    renderButtons() {
        const { addCheckedProducts, device: { isMobile = false } = {} } = this.props;

        return (
            <div block="AmastyBundleProducts" elem="ButtonsWrapper">
                <button
                    block="AmastyBundleProducts"
                    elem="QuickAddToCart"
                    mix={{ block: 'Button', mods: { isHollow: true } }}
                    onClick={() => addCheckedProducts(true)}
                >
                    {isMobile ? __('Buy bundle now') : __('Buy now')}
                </button>
                <button
                    block="AmastyBundleProducts"
                    elem="AddToCart"
                    mix={{ block: 'Button' }}
                    onClick={() => addCheckedProducts(false)}
                >
                    {isMobile ? __('Add bundle to cart') : __('Add To Cart')}
                </button>
            </div>
        );
    }

    renderPriceSection() {
        return (
            <div block="AmastyBundleProducts" elem="PriceSection">
                {this.renderRegularPrice()}
                {this.renderFinalPrice()}
                {this.renderDiscount()}
                {this.renderButtons()}
            </div>
        );
    }

    render() {
        const { items, isHiddenElement, isFirstHiddenElement } = this.props;

        if (!items?.length) {
            return null;
        }

        return (
            <div block="AmastyBundleProducts" mods={{ isHiddenElement, isFirstHiddenElement }}>
                <ContentWrapper
                    mix={{ block: 'AmastyBundleProducts', elem: 'Content' }}
                    wrapperMix={{ block: 'AmastyBundleProducts', elem: 'Wrapper' }}
                >
                    <div block="AmastyBundleProducts" elem="TopWrapper">
                        {this.renderSectionTitle()}
                    </div>
                    {this.renderItems()}
                    {this.renderPriceSection()}
                </ContentWrapper>
            </div>
        );
    }
}

export default AmastyBundleProducts;
