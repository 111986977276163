import { Product } from 'Component/Product/Product.component';

import './ProductHeader.style';

/** @namespace Pwa/Component/ProductHeader/Component/ProductHeader  */
export class ProductHeader extends Product {
    renderDesktop() {
        const { showCompareButton } = this.props;
        return (
            <>
                {this.renderName()}
                <div block="ProductHeader" elem="ActionButtons">
                    {showCompareButton && this.renderCompareButton()}
                    {this.renderWishlistButton()}
                </div>
            </>
        );
    }

    renderMobile() {
        return <>{this.renderName(true, true)}</>;
    }

    render() {
        const {
            device: { isMobile },
        } = this.props;

        return <article block="ProductHeader">{isMobile ? this.renderMobile() : this.renderDesktop()}</article>;
    }
}

export default ProductHeader;
