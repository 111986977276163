import { connect } from 'react-redux';

import AmastyBundleProductsQuery from 'Query/AmastyBundleProducts.query';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { getGuestQuoteId } from 'Util/Cart';
import history from 'Util/History';
import { fetchMutation } from 'Util/Request';
import { DataContainer } from 'Util/Request/DataContainer';

import AmastyBundleProducts from './AmastyBundleProducts.component';

/** @namespace Pwa/Component/AmastyBundleProducts/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, msg, debug = null) => dispatch(showNotification(type, msg, debug)),
    updateCartData: () => CartDispatcher.updateInitialCartData(dispatch),
});
/** @namespace Pwa/Component/AmastyBundleProducts/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    currency: state.ConfigReducer.currencyData.current_currency_code,
});

/** @namespace Pwa/Component/AmastyBundleProducts/Container/AmastyBundleProductsContainer */
export class AmastyBundleProductsContainer extends DataContainer {
    state = {
        ...this.state,
        checkedProducts: [],
    };

    componentDidMount() {
        const {
            bundle: { items = [] },
        } = this.props;

        const checkedItems = items.map((item) => ({
            itemId: item?.product?.id,
            itemQty: item?.qty,
        }));

        this.setState({ checkedProducts: checkedItems });
    }

    containerFunctions = {
        ...this.containerFunctions,
        addCheckedProducts: this.addCheckedProducts.bind(this),
        updateCheckedProducts: this.updateCheckedProducts.bind(this),
    };

    addCheckedProducts(isBuyNowButton = false) {
        const { showNotification, updateCartData } = this.props;
        const {
            bundle: { pack_id },
            mainProduct: { sku, qty },
        } = this.props;
        const cartId = getGuestQuoteId();
        const { checkedProducts } = this.state;
        const relatedProducts = checkedProducts?.map((checkedProduct) => ({
            id: checkedProduct?.itemId,
            quantity: checkedProduct?.itemQty,
        }));

        const items = [
            {
                sku,
                quantity: qty || 1,
                selected_options: [],
                entered_options: [],
                pack_id,
                amrelated_products: relatedProducts,
            },
        ];

        fetchMutation(AmastyBundleProductsQuery.addBundleProducts(cartId, items)).then(
            /** @namespace Pwa/Component/AmastyBundleProducts/Container/fetchMutation/then */
            () => {
                updateCartData();
                showNotification('success', __('Checked products was added to cart!'));

                if (isBuyNowButton) {
                    history.push('/cart');
                }
            }
        );
    }

    getItemQty(itemId) {
        const {
            bundle: { items = [] },
        } = this.props;

        const currentItem = items?.filter((item) => item?.product?.id === itemId);
        const itemQty = currentItem[0]?.qty;

        return itemQty;
    }

    updateCheckedProducts(id) {
        const { checkedProducts } = this.state;
        const updatedCheckedProducts = checkedProducts;

        if (!updatedCheckedProducts.find((item) => item?.itemId === id)) {
            updatedCheckedProducts.push({ itemId: id, itemQty: this.getItemQty(id) });
        } else {
            updatedCheckedProducts.splice(
                updatedCheckedProducts.findIndex((item) => item?.itemId === id),
                1
            );
        }

        this.setState({ checkedProducts: updatedCheckedProducts });
        this.forceUpdate();
    }

    containerProps() {
        const {
            currency,
            device,
            mainProduct,
            bundle: { apply_condition, discount_amount, discount_type, block_title = '', items = [] } = {},
            isHiddenElement,
            isFirstHiddenElement,
        } = this.props;

        const { checkedProducts } = this.state;

        return {
            mainProduct,
            currency,
            apply_condition,
            block_title,
            device,
            discount_type,
            discount_amount,
            items,
            checkedProducts,
            isHiddenElement,
            isFirstHiddenElement,
        };
    }

    render() {
        return <AmastyBundleProducts {...this.containerFunctions} {...this.containerProps()} />;
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AmastyBundleProductsContainer);
