/* eslint-disable array-callback-return */
import BlogPostsQuery from '@domneo/blog/src/query/BlogPosts.query';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import AmastyBundleProductsQuery from 'Query/AmastyBundleProducts.query';
import AmastyRelatedProductsQuery from 'Query/AmastyRelatedProducts.query';
import CartPriceRulesQuery from 'Query/CartPriceRules.query';
import ProductListQuery from 'Query/ProductList.query';
import ArrangementProduct from 'Route/ArrangementProduct/ArrangementProduct.component';
import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';
import ProductPage from 'Route/ProductPage/ProductPage.component';
import ProductSetPage from 'Route/ProductSetPage/ProductSetPage.component';
import VirtualProductPage from 'Route/VirtualProductPage/VirtualProductPage.component';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import { setArrangementBuiltProductHandler } from 'Store/ArrangementBuiltProduct/ArrangementBuiltProduct.action';
import LinkedProductsDispatcher from 'Store/LinkedProducts/LinkedProducts.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { appendProductDetails } from 'Store/Product/Product.action';
import ProductReducer from 'Store/Product/Product.reducer';
import { clearSocialProofList, showSocialProof } from 'Store/SocialProof/SocialProof.action';
import { updateConfigData } from 'Store/TradeDoublerConfig/TradeDoublerConfig.action';
import { scrollToTop } from 'Util/Browser';
import { noopFn } from 'Util/Common';
import { withReducers } from 'Util/DynamicReducer';
import { ADD_TO_CART } from 'Util/Product';
import { getBuiltProductsTotals } from 'Util/Product/Extract';
import { magentoProductTransform, transformParameters } from 'Util/Product/Transform';
import { makeCancelable } from 'Util/Promise';
import { prepareQuery } from 'Util/Query';
import { debounce, executeGet, fetchQuery, getErrorMessage } from 'Util/Request';
import { hash } from 'Util/Request/Hash';

import { ONE_MONTH_IN_SECONDS, PRODUCT_ARRANGEMENTS_ID } from './ProductPage.config';

/** @namespace Pwa/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isArrangementBuiltProduct: state.ArrangementBuiltProductReducer?.isArrangementBuiltProduct,
    arrangementSrc: state.ArrangementBuiltProductReducer?.arrangementSrc,
    amastyBundleProductsTabTitle: state.ConfigReducer.ammostviewed_bundle_packs_tab_title,
    isTablet: state.ConfigReducer.device.isTablet,
});

export const ProductDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Product/Product.dispatcher'
);

/** @namespace Pwa/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    requestProduct: (options, onDone) => {
        ProductDispatcher.then(({ default: dispatcher }) => {
            dispatcher.handleData(dispatch, options);
            onDone();
        }, LinkedProductsDispatcher.clearLinkedProducts(dispatch));
    },
    appendProductDetails: (product) => dispatch(appendProductDetails(product)),
    clearSocialProofList: () => dispatch(clearSocialProofList()),
    showSocialProof: (type, msg, debug = null) => dispatch(showSocialProof(type, msg, debug)),
    setArrangementBuiltProduct: (isArrangementBuiltProduct, arrangementSrc) =>
        dispatch(setArrangementBuiltProductHandler(isArrangementBuiltProduct, arrangementSrc)),
    clearLinkedProducts: () => {
        LinkedProductsDispatcher.clearLinkedProducts(dispatch);
    },
    updateConfigData: (configData) => dispatch(updateConfigData(configData)),
    showNotification: (type, msg, debug = null) => dispatch(showNotification(type, msg, debug)),
});

/** @namespace Pwa/Route/ProductPage/Container/ProductPageContainer */
export class ProductPageContainer extends SourceProductPageContainer {
    state = {
        ...this.state,
        socialProofsRendered: false,
        quantity: null,
        enteredOptions: this.setDefaultProductOptions('defaultEnteredOptions', 'enteredOptions'),
        selectedOptions: this.setDefaultProductOptions('defaultSelectedOptions', 'selectedOptions'),
        downloadableLinks: [],
        relatedPosts: [],
        amastyBundleProducts: {},
        amastyMainBundleProduct: {},
        amastyRelatedProducts: [],
        amastyBundleProductsLoaded: false,
        amastyRelatedProductsLoaded: false,
        cartPriceRules: {},
        isLoadingAttributes: false,
        isAmastyBundleProductsLoading: false,
    };

    containerFunctions = {
        ...this.containerFunctions,
        setQuantity: this.setQuantity.bind(this),
        copyDiscountCodeHandler: this.copyDiscountCodeHandler.bind(this),
    };

    componentDidMount() {
        this.requestProduct();
        this.updateNavigationState();
        this.updateMeta();
        this.updateHeaderState();
        this.updateBreadcrumbs();
        this.getRelatedBlogPosts();
        this.getCartPriceRules();
        scrollToTop();
    }

    setQuantity(quantity) {
        this.setState({ quantity });
    }

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            productSKU,
            product: { sku, domneo_notification, type_id },
        } = this.props;

        const { socialProofsRendered } = this.state;

        const {
            productSKU: prevProductSKU,
            product: { sku: prevSku },
        } = prevProps;

        const { sku: stateSKU } = history?.state?.state?.product || {};

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (productSKU !== prevProductSKU && stateSKU === productSKU) {
            this.updateHeaderState();
        }

        if (sku !== prevSku) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta();
        }

        this._addToRecentlyViewedProducts();
        if (!socialProofsRendered && domneo_notification && productSKU === sku) {
            this.renderSocialProof();
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ socialProofsRendered: true });
        }

        if (window.TDConf?.Config) {
            this.setTDConfigObject();
        }

        if (!!type_id && type_id !== PRODUCT_ARRANGEMENTS_ID) {
            this.getAmastyBundleProducts();
            this.getAmastyRelatedProducts();
        }
    }

    componentWillUnmount() {
        const {
            product: { type_id },
            setArrangementBuiltProduct,
            clearSocialProofList,
        } = this.props;

        clearSocialProofList();

        if (type_id !== 'built') {
            setArrangementBuiltProduct(false, '');
        }
    }

    setTDConfigObject() {
        const {
            updateConfigData,
            product,
            product: {
                type_id,
                sku,
                items = [],
                name,
                price_range: { maximum_price: { regular_price, final_price } = {} } = {},
            } = {},
        } = this.props;

        const { quantity } = this.state;

        const { special_price: builtProductPrice } = getBuiltProductsTotals({ items, ...product }, quantity);
        const arrangementPrice = (builtProductPrice || '').toString();
        const simpleProductPrice = (final_price?.value || regular_price?.value || '').toString();
        const productPrice = type_id === PRODUCT_ARRANGEMENTS_ID ? arrangementPrice : simpleProductPrice;
        const productQuantity = type_id === PRODUCT_ARRANGEMENTS_ID ? '1' : (quantity || 1).toString();
        const currency = final_price?.currency;

        const productData = {
            id: sku,
            price: productPrice,
            currency,
            name,
            qty: productQuantity,
        };

        const configData = {
            products: [],
            orderId: '',
            orderValue: '',
            pageType: 'product',
        };

        configData?.products.push(productData);

        updateConfigData({ configData });
    }

    fetchData(rawQueries, onSuccess = noopFn, onError = noopFn) {
        const preparedQuery = prepareQuery(rawQueries);
        const { query, variables } = preparedQuery;
        const queryHash = hash(query + JSON.stringify(variables));

        if (!window.dataCache) {
            window.dataCache = {};
        }

        if (window.dataCache[queryHash]) {
            onSuccess(window.dataCache[queryHash]);

            return;
        }

        this.promise = makeCancelable(executeGet(preparedQuery, this.dataModelName, ONE_MONTH_IN_SECONDS));

        this.promise.promise.then(
            /** @namespace Pwa/Route/ProductPage/Container/then */
            (response) => {
                window.dataCache[queryHash] = response;
                onSuccess(response);
            },
            /** @namespace Pwa/Route/ProductPage/Container/then */
            (err) => onError(err)
        );
    }

    getRelatedBlogPosts() {
        const { productID } = this.props;

        if (!productID) {
            return null;
        }
        this.fetchData(
            [
                BlogPostsQuery.getQuery({
                    filter: { relatedproduct_id: { eq: productID } },
                    getMedia: true,
                    getDescription: true,
                }),
            ],
            ({ posts } = {}) => {
                this.setState({
                    relatedPosts: posts?.items,
                });
            }
        );
    }

    getCartPriceRules() {
        const { productID } = this.props;

        if (productID === 0) {
            return null;
        }

        this.fetchData([CartPriceRulesQuery.getCartPriceRules(productID)], ({ pdpRules }) => {
            const cartPriceRules = pdpRules[0];

            if (!cartPriceRules) {
                this.setState({ cartPriceRules: false });
                return null;
            }
            this.setState({ cartPriceRules });
        });
    }

    getAmastyBundleProducts() {
        const { productID } = this.props;
        const { amastyBundleProductsLoaded, isAmastyBundleProductsLoading } = this.state;
        const encodedProductID = btoa(productID);

        if (amastyBundleProductsLoaded || isAmastyBundleProductsLoading) {
            return null;
        }
        this.setState({ isAmastyBundleProductsLoading: true });
        this.fetchData(
            [AmastyBundleProductsQuery.getAmastyBundleProducts(encodedProductID)],
            ({ amMostviewedBundlePacks: { items, main_product } }) => {
                if (!items) {
                    this.setState({
                        amastyBundleProducts: false,
                        amastyMainBundleProduct: false,
                        isAmastyBundleProductsLoading: false,
                        amastyBundleProductsLoaded: true,
                    });

                    return null;
                }
                const amastyBundleProducts = items;
                const amastyMainBundleProduct = main_product;
                this.setState({
                    amastyBundleProducts,
                    amastyMainBundleProduct,
                    isAmastyBundleProductsLoading: false,
                    amastyBundleProductsLoaded: true,
                });
            }
        );
    }

    getAmastyRelatedProductsQuery(id, position) {
        this.fetchData(
            [AmastyRelatedProductsQuery.getAmastyRelatedProducts(id, position)],
            ({ amMostviewedGroups: { items } }) => {
                if (!items) {
                    return null;
                }
                this.setState({ amastyRelatedProducts: items });
            }
        );
    }

    getAmastyRelatedProducts() {
        const { productID } = this.props;
        const { amastyRelatedProductsLoaded } = this.state;
        const encodedProductID = btoa(productID);

        if (amastyRelatedProductsLoaded) {
            return null;
        }

        this.setState({ amastyRelatedProductsLoaded: true });
        this.getAmastyRelatedProductsQuery(encodedProductID, '');
    }

    setDefaultProductOptions(keyProp, keyState) {
        const { [keyProp]: value } = this.props;

        if (Array.isArray(value) && value.length > 0) {
            this.setState({ [keyState]: value || [] }, () => {
                this.updateAdjustedPrice();
            });
        }

        return value || [];
    }

    getMagentoProduct() {
        const { quantity, enteredOptions, selectedOptions, downloadableLinks, parameters } = this.state;

        const {
            product,
            product: { attributes },
        } = this.props;

        const configurableOptions = transformParameters(parameters, attributes);

        return magentoProductTransform(ADD_TO_CART, product, quantity, enteredOptions, [
            ...selectedOptions,
            ...downloadableLinks,
            ...configurableOptions,
        ]);
    }

    isProductInformationTabEmpty() {
        const { description: { html = '' } = {} } = this.getDataSource();
        const { short_description: { html: short_description_html = '' } = {} } = this.getDataSource();
        const htmlElement = new DOMParser().parseFromString(html, 'text/html');

        return !short_description_html && !htmlElement?.body?.innerText;
    }

    getDataSource() {
        const { productSKU, product } = this.props;

        const { sku } = product;

        if (productSKU === sku) {
            return product;
        }

        return {};
    }

    renderSocialProof() {
        const {
            showSocialProof,
            product: { domneo_notification },
            productID,
        } = this.props;

        if (!domneo_notification) {
            return null;
        }

        if (document.cookie.split(';').some((item) => item.includes(`socialProof_${productID}=enabled`))) {
            return null;
        }

        domneo_notification.map((notification) => {
            showSocialProof(
                'info',
                <>
                    <p>{notification?.bought_qty}</p>
                    <p>{notification?.date_last_purchase}</p>
                    <p>{notification?.popularity}</p>
                    <p>{notification?.qty_left}</p>
                </>
            );
        });

        document.cookie = `socialProof_${productID}=enabled; max-age=86400`;

        return null;
    }

    copyDiscountCodeHandler(coupon_code) {
        const { showNotification } = this.props;

        navigator.clipboard.writeText(coupon_code);
        showNotification('success', __('Discount code has been copied'));
    }

    requestProduct() {
        const { requestProduct, productSKU } = this.props;
        const { currentProductSKU } = this.state;

        if (!productSKU) {
            return;
        }

        if (currentProductSKU === productSKU) {
            return;
        }

        this.setState({ currentProductSKU: productSKU });

        const options = {
            isSingleProduct: true,
            noAttributes: true,
            args: { filter: this.getProductRequestFilter() },
        };

        requestProduct(options, () => {
            this.requstProductAttributes();
        });
    }

    async requstProductAttributes() {
        const { appendProductDetails } = this.props;

        this.setState({ isLoadingAttributes: true });

        try {
            const data = await fetchQuery(
                ProductListQuery.getQuery({
                    args: { filter: this.getProductRequestFilter() },
                    onlyAttributesAndReviews: true,
                })
            );

            appendProductDetails(data?.products?.items[0]);
        } catch (e) {
            showNotification('error', getErrorMessage(e), e);
        } finally {
            this.setState({ isLoadingAttributes: false });
        }
    }

    containerProps() {
        const {
            product: { attachments },
            isArrangementBuiltProduct,
            arrangementSrc,
            amastyBundleProductsTabTitle,
            isTablet,
        } = this.props;

        const {
            quantity,
            relatedPosts,
            amastyBundleProducts,
            amastyMainBundleProduct,
            amastyRelatedProducts,
            cartPriceRules,
            isLoadingAttributes,
            amastyBundleProductsLoaded,
        } = this.state;

        const magentoProduct = this.getMagentoProduct();

        return {
            ...super.containerProps(),
            attachments,
            isArrangementBuiltProduct,
            arrangementSrc,
            quantity,
            magentoProduct,
            relatedPosts,
            amastyBundleProducts,
            amastyMainBundleProduct,
            amastyRelatedProducts,
            cartPriceRules,
            amastyBundleProductsTabTitle,
            isTablet,
            isLoadingAttributes,
            amastyBundleProductsLoaded,
        };
    }

    render() {
        const {
            type_id,
            show_as_set = false,
            is_virtual_design: isVirtualProduct = false,
        } = this.getActiveProductDataSource();

        if (isVirtualProduct) {
            return <VirtualProductPage {...this.containerFunctions} {...this.containerProps()} />;
        }

        if (type_id === PRODUCT_ARRANGEMENTS_ID && show_as_set) {
            return <ProductSetPage {...this.containerFunctions} {...this.containerProps()} />;
        }

        if (type_id === PRODUCT_ARRANGEMENTS_ID) {
            return <ArrangementProduct {...this.containerFunctions} {...this.containerProps()} />;
        }

        return <ProductPage {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default withReducers({
    ProductReducer,
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer)));
